<template>
  <div class="modal-wrap">
    <h1 class="tit-h1 d-flex">
      이미지 설정
      <div class="ml-auto align-self-center">
        <v-icon x-small class="svg-close20" @click="$emit('hide')"></v-icon>
      </div>
    </h1>
    <div class="modal-box">
      <div>
        <div >
          <div class="d-flex align-center">
            <vue-dropzone
                ref="myVueDropzone"
                id="dropzone"
                :options="dropzoneOptions"
                @vdropzone-success="uploadSuccess"
                class="form-drop"
            ></vue-dropzone>
          </div>
          <div class="d-flex mt-5">
            <div>
              <p class="text-subtitle-2">*확장자는 <strong class="clr-error">png, jpg, gif</strong> , 용량은 </strong>500 KB 이하</strong>이며, 최대 <strong class="clr-error">1개</strong>까지 등록 가능합니다.</p>
              <p class="text-subtitle-2">*이미지 사이즈는 <strong class="clr-error">720px * 720px</strong>를 권장하며, <strong class="clr-error">가로 500px 미만</strong> 이미지는 사용할 수 없습니다.</p>
              <p class="text-subtitle-2">*가로:세로 비율이 <strong class="clr-error">2:1 미만</strong>이거나 <strong class="clr-error">3:4 초과</strong>하는 이미지는 사용할 수 없습니다.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-3 text-right">
        <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnCancle')" outlined class="btn-etc"    @click="popupBtnClose">취소</v-btn>
        <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnDelete')" outlined class="btn-etc ml-2" @click="popupBtnDelete">삭제</v-btn>
        <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSave')" outlined class="btn-point ml-2"   @click="popupBtnUpload">저장</v-btn>
      </div>
    </div>
  </div>
</template>

<script>

import {mixin} from "../../mixin/mixin";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  name: "MENU_M810602P02", //name은 'MENU_' + 파일명 조합
  props: {
    param: {
      type: Object,
    },
    headerTitle: {
      type: String,
      default: "문의유형 상세",
    },
    fileInfo: {
      type: Object,
      value: {},
    },
  },
  components: {
    vueDropzone: vue2Dropzone,
    // Ckeditor,
  },
  mixins:[mixin],

  data() {
    return {
      gridListSelected: [],
      files: [],
      dropzoneOptions: {
        autoProcessQueue: false,
        url: process.env.VUE_APP_API_URL + "api/file/chat/images/uploads",
        //url: "https://dev.hkpalette.com/" + "api/file/chat/images/uploads",
        maxThumbnailFilesize: 1,
        thumbnailWidth: 150,
        thumbnailHeight: 150,
        thumbnailMethod: "crop",
        maxFilesize: 100,//this.fileInfo.maxSize,
        maxFiles: 1,//this.fileInfo.multi,
        addRemoveLinks: true,
        clickable: true,
        dictRemoveFile: '삭제',
        dictCancelUpload: "취소",
        //acceptedFiles: "[.png,.jpg,.gif]",
        acceptedFiles: ".png,.jpg,.gif",
        parallelUploads: 1,
        uploadMultiple: false,
        filesizeBase: 1000,

        paramName: "userfiles",
        /*
        paramName: function(e){
          return "userfiles"
        },
        */

        headers: {
          'Authorization': "",
        },
        params: {
          'aspNewCustKey': this.param.ASP_CUST_KEY,
          'aspCustKey': this.param.ASP_CUST_KEY,
          'procId': this.$store.getters['userStore/GE_USER_ROLE'].userId,
          'acceptedFiles': this.param.FILE_NEWTY,
          'uploadUri': "/api/file/chat/images/uploads",
          'downloadUri': "/api/file/chat/images/downloads",
          'fileUploadLib': "dropzone",
          'dir': "/PALETTE2/hkcloud/project/deploy/production/repository/web/chat/images",
          //'maxFilesize': 5,
          'tempDir': "/PALETTE2/hkcloud/project/deploy/production/repository/web/chat/images/temp",
          'targetType': "FILE",
          'fileGroupKey' : this.param.FILE_GROUP_KEY,
        },
      },
    };
  },

  methods: {
    popupBtnClose() {
      this.files = [];
      this.$emit('hide')
    },

    popupBtnDelete() {
      this.$emit("submitDelete", null);
      this.popupBtnClose();
    },

    popupBtnUpload(){
      let token = window.sessionStorage.getItem("token");
      this.dropzoneOptions.headers.Authorization = "bearer " + token;

      let get_files = this.$refs.myVueDropzone.getQueuedFiles();
      console.log("get_files :: " + JSON.stringify(get_files));

      console.log("get_files.length :: " + get_files.length);

      for (let i = 0;i<get_files.length;i++){
        this.files[i] = get_files[i].upload.filename;
      }

      console.log("fileType :: " + typeof(this.files));
      console.log("file :: " + this.files);

      this.$refs.myVueDropzone.processQueue();

    },

    uploadSuccess(file, response){
      console.log("================ file ================")
      console.log(file)
      console.log("================ response ================")
      console.log(response)

      this.$emit('submitUpload', response.data);
    },


  },

  computed: {

  },

  created() {},
  mounted() {},
  updated() {},
  destroyed() {},
};

</script>

<style></style>